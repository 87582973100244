const moment = require("moment");

export function displayAlert(message, type = 0, fixed = false) {
  this.$root.$emit("display-alert", {
    message: message,
    type: type,
    fixed: fixed,
  });
}

// capitalize all words
export function capitalizeAll(value) {
  if (!value) return "";

  const values = value.split(" ");

  return values
    .map((e) => capitalize(e))
    .toString()
    .replaceAll(",", " ");
}

// capitalize first word
export function capitalize(value) {
  if (!value) return "";
  return (
    value
      .toString()
      .charAt(0)
      .toUpperCase() +
    value
      .toString()
      .substring(1)
      .toLowerCase()
  );
}

// set all to upercase
export function upperCase(value) {
  if (!value) return "";
  return value.toString().toUpperCase();
}

export function formatAmount(data, decimal = 2) {
  // change number of digits decimal point
  if (!data) return data;

  const og = Math.pow(10, decimal);

  data = Math.floor(data * og) / og;

  return Number(data.toFixed(decimal));
}

export function moneyMask(data, max = 2, min = 2, locale = "pt") {
  // add currency mask formatting
  if (!data) return data;

  data = data.toString().replace(",", ".");

  return Number(data).toLocaleString(locale, {
    maximumFractionDigits: max,
    minimumFractionDigits: min,
  });
}

export function parseNumber(data) {
  // remove money mask
  try {
    return Number(data.replace(/\./g, "").replace(/,/g, "."));
  } catch {
    return data;
  }
}

export function formatDate(data) {
  if (!data) return "empty";
  return moment(data).format("YYYY-MM-DD hh:mm");
}